<template>
  <div class="container">
    <p class="text-wilmsBlue text-2xl font-bold mt-12 mb-6">{{ $t('Your locations') }}</p>
    <div class="flex justify-between -mb-4 lg:flex-col">
      <div class="bg-wilmsBlueBackground rounded-sm flex max-w-max">
        <div class="cursor-pointer m-2 py-1 px-3 text-wilmsBlue font-bold rounded-sm whitespace-nowrap" @click="filterHomes('all')" :class="{ 'bg-wilmsBlue text-white': 'all' === group }">
          {{ $t('All') }}
        </div>
        <div class="cursor-pointer m-2 py-1 px-3 text-wilmsBlue font-bold rounded-sm whitespace-nowrap" @click="filterHomes('individual')" :class="{ 'bg-wilmsBlue text-white': 'individual' === group }">
          {{ $t('Individuals') }}
        </div>
        <div class="cursor-pointer m-2 py-1 px-3 text-wilmsBlue font-bold rounded-sm whitespace-nowrap" @click="filterHomes('professional')" :class="{ 'bg-wilmsBlue text-white': 'professional' === group }">
          {{ $t('Professionals') }}
        </div>
        <div class="cursor-pointer m-2 py-1 px-3 text-wilmsBlue font-bold rounded-sm whitespace-nowrap" @click="filterHomes('urgent')" :class="{ 'bg-wilmsBlue text-white': 'urgent' === group }">
          {{ $t('Only urgent') }}
        </div>
      </div>
      <div class="right flex lg:mt-3 sm:flex-col">
        <div class="relative mr-3">
          <input class="bg-wilmsBlueBackground py-3 pl-12 pr-4 w-72 rounded-sm focus:outline-none placeholder-gray-500 font-semibold"
            type="search" name="search" :placeholder="$t('Search by family or location')" v-model="search" @keyup="searchHomes()" @search="searchHomes()">
          <Icon class="absolute left-0 top-0 mt-4 ml-4 cursor-pointer text-gray-500" icon="fa:search" @click="searchHomes()"/>
        </div>
        <div @click="openModal()" class="cursor-pointer bg-wilmsBlue py-3 px-3 text-white font-semibold rounded-sm whitespace-nowrap sm:mt-3 max-w-max">
          {{ $t('Add a new location') }}
        </div>
      </div>
    </div>
    <Map :homes="this.locations" />
    <p class="text-wilmsBlue text-xl font-bold mt-10 mb-6 whitespace-nowrap" id="warnings">{{ $t('Locations with warnings') }}</p>
    <div class="grid grid-cols-4 gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      <div class="shadow-lg w-full rounded-sm" v-for="location in warningLocations" :key="location.home.id" @click="selectedLocation = location">
        <div class="relative">
          <div class="absolute h-12 w-12 bg-white rounded-full flex justify-center right-0 -mt-3 -mr-3" style="z-index:1000">
            <Icon class="text-red-600 my-auto text-3xl" icon="fa:exclamation-circle"/>
          </div>
          <MiniMap :home="location"/>
        </div>
        <router-link :to="{ name: 'iot.installer.details', params: { home: location.home.id }}">
          <div class="ml-3 mb-3">
            <p class="text-wilmsBlue text-lg font-bold mt-3">{{ location.home.name }}</p>
            <p class="text-gray-500 font-medium -mt-1">{{ location.home.address }}</p>
            <p class="text-gray-500 font-medium -mt-1">{{ location.home.postcode + ' ' + location.home.city }}</p>
            <div class="border-2 border-wilmsBlue w-max rounded-sm px-2 text-sm text-wilmsBlue font-medium mt-1">{{ totalDevices(location.home) }}</div>
          </div>
          <div class="bg-red-200 rounded-b-sm">
            <p class="text-red-600 ml-3">{{ $t('Urgent maintenance') }}!</p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="flex mt-10 mb-6">
      <p class="text-wilmsBlue text-xl font-bold mr-4 whitespace-nowrap">{{ $t('All locations') }}</p>
      <p class="text-wilmsLightBlue text-sm mt-1 whitespace-nowrap">{{ $t('Sorted by') }} </p>
      <select v-model="currentSort" @change="sortLocations()" class="text-wilmsLightBlue text-sm mt-1 font-semibold my-auto bg-wilmsBackground outline-none max-w-full">
        <option value="planned">{{ $t('Planned maintenance') }}</option>
        <option value="city">{{ $t('City') }}</option>
        <option value="name">{{ $t('Name') }}</option>
      </select>
    </div>
    <div class="grid grid-cols-4 gap-5 mb-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      <div class="shadow-lg w-full rounded-sm" v-for="location in locations" :key="location.home.id" @click="selectedLocation = location">
        <MiniMap :home="location" />
        <router-link :to="{ name: 'iot.installer.details', params: { home: location.home.id }}">
          <div class="ml-3 mb-3">
              <p class="text-wilmsBlue text-lg font-bold mt-3">{{ location.home.name }}</p>
            <p class="text-gray-500 font-medium -mt-1">{{ location.home.address }}</p>
            <p class="text-gray-500 font-medium -mt-1">{{ location.home.postcode + ' ' + location.home.city }}</p>
            <div class="border-2 border-wilmsBlue w-max rounded-sm px-2 text-sm text-wilmsBlue font-medium mt-1">{{ totalDevices(location.home) }}</div>
          </div>
        </router-link>
        <div class="bg-wilmsBlueBackground rounded-b-sm">
          <p class="text-wilmsBlue ml-3">{{ $t('Planned maintenance') }}: {{ getDate(location.home.planned) }}</p>
        </div>
      </div>
    </div>
    <CreateHomeModal @close="open = false" :open="open"></CreateHomeModal>
  </div>
</template>

<script>
import moment from 'moment'
import Map from '@/components/iot/maps/Map'
import MiniMap from '@/components/iot/maps/MiniMap'
import CreateHomeModal from '@/components/iot/modals/CreateHome'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    CreateHomeModal,
    Map,
    MiniMap,
    Icon
  },
  data () {
    return {
      selectedLocation: null,
      locations: null,
      open: false,
      group: 'all',
      search: '',
      currentSort: 'planned'
    }
  },
  async mounted () {
    this.$emit('changeHeader', [[this.$t('IOT'), { name: 'iot.installer' }], [this.$t('Locations'), { name: 'iot.installer.homes' }]])
    await this.fetchData()
    this.locations = this.$store.getters['HomesModule/getHomes']
    this.sortLocations()
  },
  methods: {
    async fetchData () {
      if (this.$store.getters['HomesModule/getHomes'].length === 0) await this.$store.dispatch('HomesModule/fetchHomes')
    },
    totalDevices (home) {
      let total = 0
      home.rooms.forEach(room => { total += room.devices.length })
      return total === 1 ? total + ' unit' : total + ' units'
    },
    openModal () {
      this.open = true
    },
    filterHomes (group) {
      this.group = group
      if (group === 'all') this.locations = this.$store.getters['HomesModule/getHomes']
      else if (group === 'urgent') this.locations = this.$store.getters['HomesModule/getHomes'].filter(location => location.home.warnings > 0)
      else this.locations = this.$store.getters['HomesModule/getHomes'].filter(home => home.home.type === group)
      this.searchHomes()
      this.sortLocations()
    },
    searchHomes () {
      let newLocations
      if (this.group === 'all') newLocations = this.$store.getters['HomesModule/getHomes']
      else if (this.group === 'urgent') newLocations = this.$store.getters['HomesModule/getHomes'].filter(location => location.home.warnings > 0)
      else newLocations = this.$store.getters['HomesModule/getHomes'].filter(home => home.home.type === this.group)
      this.locations = newLocations.filter(home => home.home.name.toLowerCase().includes(this.search.toLowerCase()) || (home.home.address + ' ' + home.home.city + ' ' + home.home.postcode + ' ').toLowerCase().includes(this.search.toLowerCase()))
      this.sortLocations()
    },
    getDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    sortLocations () {
      const currentSort = this.currentSort
      this.locations.sort(function (a, b) {
        if (currentSort === 'planned') {
          if (a.home.planned < b.home.planned) return -1
          if (a.home.planned > b.home.planned) return 1
        } else if (currentSort === 'city') {
          if (a.home.city.toLowerCase() < b.home.city.toLowerCase()) return -1
          if (a.home.city.toLowerCase() > b.home.city.toLowerCase()) return 1
        } else {
          if (a.home.name.toLowerCase() < b.home.name.toLowerCase()) return -1
          if (a.home.name.toLowerCase() > b.home.name.toLowerCase()) return 1
        }
        return 0
      })
    }
  },
  computed: {
    warningLocations () {
      if (this.locations) return this.locations.filter(location => location.home.warnings > 0)
      return []
    }
  }
}
</script>
